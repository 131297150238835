import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class BindServiceDTO
{
    release_guid?: Nullable<string>;
    release_id?: Nullable<number>;
    service_id?: Nullable<number>;
    service_version?: Nullable<string>;
    service_release_date?: Nullable<string>;

    constructor({ release_guid, release_id, service_id, service_version, service_release_date}: {release_guid:Nullable<string>, release_id?: Nullable<number>, service_id?: Nullable<number>, service_version?: Nullable<string>, service_release_date?: Nullable<string>}) {
        this.release_guid = release_guid;
        this.release_id = release_id;
        this.service_id = service_id;
        this.service_version = service_version;
        this.service_release_date = service_release_date;
    }
}

export default class BindService extends AbstractBaseEntity
{
    private release_guid: string;
    private release_id: number;
    private service_id: number;
    private service_version: string;
    private service_release_date: string;

    constructor(
      release_guid: string,
      release_id: number,
      service_id: number,
      service_version: string,
      service_release_date: string
    ) {
        super();
        this.release_guid = release_guid;
        this.release_id = release_id;
        this.service_id = service_id;
        this.service_version = service_version;
        this.service_release_date = service_release_date;
    }

    static create(dto: BindServiceDTO): BindService
    {
        return new BindService(
            dto.release_guid,
            dto.release_id,
            dto.service_id,
            dto.service_version,
            dto.service_release_date
        );
    }

    setReleaseGuid(release_guid: string): void
    {
        this.release_guid = release_guid;
    }

    getReleaseGuid(): string
    {
        return this.release_guid;
    }

    setReleaseId(release_id: number): void
    {
        this.release_id = release_id;
    }

    getReleaseId(): number
    {
        return this.release_id;
    }

    setServiceId(service_id: number): void
    {
        this.service_id = service_id;
    }

    getServiceId(): number
    {
        return this.service_id;
    }

    setServiceVersion(service_version: string): void
    {
        this.service_version = service_version;
    }

    getServiceVersion(): string
    {
        return this.service_version;
    }

    setServiceReleaseDate(service_release_date: string): void
    {
        this.service_release_date = service_release_date;
    }

    getServiceReleaseDate(): string
    {
        return this.service_release_date;
    }
}
